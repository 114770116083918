import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cd0a3ddc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "img grayscale" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "part-info" }
const _hoisted_5 = { class: "machinix-pn-rev" }
const _hoisted_6 = { class: "machinix-rev" }
const _hoisted_7 = {
  key: 0,
  class: "tag-rev"
}
const _hoisted_8 = { class: "customer-pn-rev" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["TooltipWrapper"], {
    text: "This part is connected to a deleted rfq or rfq-part.",
    disabled: $setup.isPartInfoRoutable
  }, {
    trigger: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["part-card", {clickable: $setup.isPartInfoRoutable}]),
        onClick: $setup.goToPartInfoPage
      }, [
        _createElementVNode("div", _hoisted_1, [
          ($props.part?.link)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: $props.part.link
              }, null, 8, _hoisted_2))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: require('@/assets/placeholders/order-details/order-thumbnail-placeholder.png')
              }, null, 8, _hoisted_3))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode($setup["TextWithTooltip"], {
              class: "machinix-pn",
              text: $props.part.number
            }, null, 8, ["text"]),
            _createElementVNode("div", _hoisted_6, [
              ($props.part?.rev)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode($setup["TextWithTooltip"], {
                      text: `REV ${$props.part.rev}`
                    }, null, 8, ["text"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode($setup["TextWithTooltip"], {
              class: "customer-pn",
              text: $props.part.customerPN
            }, null, 8, ["text"])
          ]),
          ($props.part.description)
            ? (_openBlock(), _createBlock($setup["TextWithTooltip"], {
                key: 0,
                class: "description",
                text: $props.part.description
              }, null, 8, ["text"]))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ]),
    _: 1
  }, 8, ["disabled"]))
}